
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as email_45verifiedOEGNMLiS1uMeta } from "/vercel/path0/layers/modules/fubex-auth/pages/email-verified.vue?macro=true";
import { default as indexUcQPUT7V7bMeta } from "/vercel/path0/apps/bitbelicash/pages/index.vue?macro=true";
import { default as _91userId_93mcHhgwm1bvMeta } from "/vercel/path0/layers/modules/fubex-auth/pages/invite/[userId].vue?macro=true";
import { default as login0CAY3R3TM3Meta } from "/vercel/path0/layers/modules/fubex-auth/pages/login.vue?macro=true";
import { default as logout4UhjsdEf4TMeta } from "/vercel/path0/layers/modules/fubex-auth/pages/logout.vue?macro=true";
import { default as _91uuid_93zf836kyoD0Meta } from "/vercel/path0/apps/bitbelicash/pages/order/[uuid].vue?macro=true";
import { default as bank_45accountsCEFxJ4x8WhMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/account/bank-accounts.vue?macro=true";
import { default as change_45passwordaYg8Y21W9sMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/account/change-password.vue?macro=true";
import { default as indexBgHzhQsNCfMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/account/index.vue?macro=true";
import { default as verifynMgjgqchhuMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/account/verify.vue?macro=true";
import { default as accountbENOmKO8wKMeta } from "/vercel/path0/apps/bitbelicash/pages/profile/account.vue?macro=true";
import { default as bank_45accountsAtXfGwxlLdMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/bank-accounts.vue?macro=true";
import { default as _91id_93jkz3ACVgfPMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/bank/[id].vue?macro=true";
import { default as indexDt2iL7IAEJMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/bank/index.vue?macro=true";
import { default as chargesCslMSoduU0Meta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/charges.vue?macro=true";
import { default as _91id_93UGqb1svznUMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/exchange-office/[id].vue?macro=true";
import { default as _91id_93sdDQwuez7qMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/exchange-office/branches/[id].vue?macro=true";
import { default as indexd9A2wfSoMKMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/exchange-office/index.vue?macro=true";
import { default as indexpdLofxmh4kMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/index.vue?macro=true";
import { default as kyb2uEk8nTt4NMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/kyb.vue?macro=true";
import { default as _91id_93AFPGGD6FdDMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/orders/[id].vue?macro=true";
import { default as indexhZBvy7r2suMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/orders/index.vue?macro=true";
import { default as overlimitJxVyx19wM1Meta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/overlimit.vue?macro=true";
import { default as statisticssZoYIpuSzuMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/statistics.vue?macro=true";
import { default as transactionsavQgegseq4Meta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/transactions.vue?macro=true";
import { default as _91id_93083RisL263Meta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/users/[id].vue?macro=true";
import { default as indexOwnxsORKUnMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/users/index.vue?macro=true";
import { default as adminq5aC74u6KBMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/admin.vue?macro=true";
import { default as indexzJpdxfXJ5pMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/bonus/index.vue?macro=true";
import { default as rewardsQqLFjCQAGJMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/bonus/rewards.vue?macro=true";
import { default as withdrawalsvppnnnmfIIMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/bonus/withdrawals.vue?macro=true";
import { default as bonusbrvmpruqgkMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/bonus.vue?macro=true";
import { default as exchangemVVCRfGtARMeta } from "/vercel/path0/apps/bitbelicash/pages/profile/exchange.vue?macro=true";
import { default as _91id_93un4E7Q424KMeta } from "/vercel/path0/apps/bitbelicash/pages/profile/exchangeOffice/branches/[id].vue?macro=true";
import { default as indexFA5rdbQ4NGMeta } from "/vercel/path0/apps/bitbelicash/pages/profile/exchangeOffice/branches/index.vue?macro=true";
import { default as clientslpQqvmKWREMeta } from "/vercel/path0/apps/bitbelicash/pages/profile/exchangeOffice/clients.vue?macro=true";
import { default as employees614iPfGJBPMeta } from "/vercel/path0/apps/bitbelicash/pages/profile/exchangeOffice/employees.vue?macro=true";
import { default as indexvkZ9amdtzyMeta } from "/vercel/path0/apps/bitbelicash/pages/profile/exchangeOffice/index.vue?macro=true";
import { default as settingsXgzFrlV2SZMeta } from "/vercel/path0/apps/bitbelicash/pages/profile/exchangeOffice/settings.vue?macro=true";
import { default as transactionsIMcnAR2S5LMeta } from "/vercel/path0/apps/bitbelicash/pages/profile/exchangeOffice/transactions.vue?macro=true";
import { default as exchangeOfficeyQslI2ShU0Meta } from "/vercel/path0/apps/bitbelicash/pages/profile/exchangeOffice.vue?macro=true";
import { default as history2zHqZDjg7xMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/history.vue?macro=true";
import { default as indexmpq3T4LE2UMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/index.vue?macro=true";
import { default as _91id_93URb04CiBFIMeta } from "/vercel/path0/apps/bitbelicash/pages/profile/orders/[id].vue?macro=true";
import { default as overviewkjPHj3odDkMeta } from "/vercel/path0/apps/bitbelicash/pages/profile/overview.vue?macro=true";
import { default as verify_45resultCVW43beFqrMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/verify-result.vue?macro=true";
import { default as chargesfugUAF14EuMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/inspector/charges.vue?macro=true";
import { default as indexGP8VZusDIUMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/inspector/index.vue?macro=true";
import { default as orders2b4VlISxpuMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/inspector/orders.vue?macro=true";
import { default as transactions9aYnKbcNXzMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/inspector/transactions.vue?macro=true";
import { default as usersnnC9YYhwWWMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/inspector/users.vue?macro=true";
import { default as inspectoriUqJe6hPJEMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile/inspector.vue?macro=true";
import { default as profileVFLmNfYR3cMeta } from "/vercel/path0/layers/modules/fubex-profile/pages/profile.vue?macro=true";
import { default as signup_45successQZWtvdzDqmMeta } from "/vercel/path0/layers/modules/fubex-auth/pages/signup-success.vue?macro=true";
import { default as update_45passwordaASOoFSWapMeta } from "/vercel/path0/layers/modules/fubex-auth/pages/update-password.vue?macro=true";
import { default as verify_45resultVpvCaEvxdWMeta } from "/vercel/path0/apps/bitbelicash/pages/verify-result.vue?macro=true";
export default [
  {
    name: "email-verified",
    path: "/email-verified",
    component: () => import("/vercel/path0/layers/modules/fubex-auth/pages/email-verified.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/apps/bitbelicash/pages/index.vue")
  },
  {
    name: "invite-userId",
    path: "/invite/:userId()",
    component: () => import("/vercel/path0/layers/modules/fubex-auth/pages/invite/[userId].vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/vercel/path0/layers/modules/fubex-auth/pages/login.vue")
  },
  {
    name: "logout",
    path: "/logout",
    component: () => import("/vercel/path0/layers/modules/fubex-auth/pages/logout.vue")
  },
  {
    name: "order-uuid",
    path: "/order/:uuid()",
    meta: _91uuid_93zf836kyoD0Meta || {},
    component: () => import("/vercel/path0/apps/bitbelicash/pages/order/[uuid].vue")
  },
  {
    name: "profile-parent",
    path: "/profile",
    meta: profileVFLmNfYR3cMeta || {},
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile.vue"),
    children: [
  {
    name: "account",
    path: "account",
    meta: accountbENOmKO8wKMeta || {},
    component: () => import("/vercel/path0/apps/bitbelicash/pages/profile/account.vue"),
    children: [
  {
    name: "profile-account-bank-accounts",
    path: "bank-accounts",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/account/bank-accounts.vue")
  },
  {
    name: "profile-account-change-password",
    path: "change-password",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/account/change-password.vue")
  },
  {
    name: "profile-account",
    path: "",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/account/index.vue")
  },
  {
    name: "profile-account-verify",
    path: "verify",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/account/verify.vue")
  }
]
  },
  {
    name: adminq5aC74u6KBMeta?.name,
    path: "admin",
    meta: adminq5aC74u6KBMeta || {},
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin.vue"),
    children: [
  {
    name: "profile-admin-bank-accounts",
    path: "bank-accounts",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/bank-accounts.vue")
  },
  {
    name: "profile-admin-bank-id",
    path: "bank/:id()",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/bank/[id].vue")
  },
  {
    name: "profile-admin-bank",
    path: "bank",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/bank/index.vue")
  },
  {
    name: "profile-admin-charges",
    path: "charges",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/charges.vue")
  },
  {
    name: "profile-admin-exchange-office-id",
    path: "exchange-office/:id()",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/exchange-office/[id].vue")
  },
  {
    name: "profile-admin-exchange-office-branches-id",
    path: "exchange-office/branches/:id()",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/exchange-office/branches/[id].vue")
  },
  {
    name: "profile-admin-exchange-office",
    path: "exchange-office",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/exchange-office/index.vue")
  },
  {
    name: "profile-admin",
    path: "",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/index.vue")
  },
  {
    name: "profile-admin-kyb",
    path: "kyb",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/kyb.vue")
  },
  {
    name: "profile-admin-orders-id",
    path: "orders/:id()",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/orders/[id].vue")
  },
  {
    name: "profile-admin-orders",
    path: "orders",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/orders/index.vue")
  },
  {
    name: "profile-admin-overlimit",
    path: "overlimit",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/overlimit.vue")
  },
  {
    name: "profile-admin-statistics",
    path: "statistics",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/statistics.vue")
  },
  {
    name: "profile-admin-transactions",
    path: "transactions",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/transactions.vue")
  },
  {
    name: "profile-admin-users-id",
    path: "users/:id()",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/users/[id].vue")
  },
  {
    name: "profile-admin-users",
    path: "users",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/users/index.vue")
  }
]
  },
  {
    name: "bonus",
    path: "bonus",
    meta: bonusbrvmpruqgkMeta || {},
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/bonus.vue"),
    children: [
  {
    name: "profile-bonus",
    path: "",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/bonus/index.vue")
  },
  {
    name: "profile-bonus-rewards",
    path: "rewards",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/bonus/rewards.vue")
  },
  {
    name: "profile-bonus-withdrawals",
    path: "withdrawals",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/bonus/withdrawals.vue")
  }
]
  },
  {
    name: "profile-exchange",
    path: "exchange",
    component: () => import("/vercel/path0/apps/bitbelicash/pages/profile/exchange.vue")
  },
  {
    name: "exchangeOffice",
    path: "exchangeOffice",
    meta: exchangeOfficeyQslI2ShU0Meta || {},
    component: () => import("/vercel/path0/apps/bitbelicash/pages/profile/exchangeOffice.vue"),
    children: [
  {
    name: "profile-exchangeOffice-branches-id",
    path: "branches/:id()",
    component: () => import("/vercel/path0/apps/bitbelicash/pages/profile/exchangeOffice/branches/[id].vue")
  },
  {
    name: "profile-exchangeOffice-branches",
    path: "branches",
    component: () => import("/vercel/path0/apps/bitbelicash/pages/profile/exchangeOffice/branches/index.vue")
  },
  {
    name: "profile-exchangeOffice-clients",
    path: "clients",
    component: () => import("/vercel/path0/apps/bitbelicash/pages/profile/exchangeOffice/clients.vue")
  },
  {
    name: "profile-exchangeOffice-employees",
    path: "employees",
    component: () => import("/vercel/path0/apps/bitbelicash/pages/profile/exchangeOffice/employees.vue")
  },
  {
    name: "profile-exchangeOffice",
    path: "",
    component: () => import("/vercel/path0/apps/bitbelicash/pages/profile/exchangeOffice/index.vue")
  },
  {
    name: "profile-exchangeOffice-settings",
    path: "settings",
    component: () => import("/vercel/path0/apps/bitbelicash/pages/profile/exchangeOffice/settings.vue")
  },
  {
    name: "profile-exchangeOffice-transactions",
    path: "transactions",
    component: () => import("/vercel/path0/apps/bitbelicash/pages/profile/exchangeOffice/transactions.vue")
  }
]
  },
  {
    name: "profile-history",
    path: "history",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/history.vue")
  },
  {
    name: "profile",
    path: "",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/index.vue")
  },
  {
    name: adminq5aC74u6KBMeta?.name,
    path: "admin",
    meta: adminq5aC74u6KBMeta || {},
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin.vue"),
    children: [
  {
    name: "profile-admin-bank-accounts",
    path: "bank-accounts",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/bank-accounts.vue")
  },
  {
    name: "profile-admin-bank-id",
    path: "bank/:id()",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/bank/[id].vue")
  },
  {
    name: "profile-admin-bank",
    path: "bank",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/bank/index.vue")
  },
  {
    name: "profile-admin-charges",
    path: "charges",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/charges.vue")
  },
  {
    name: "profile-admin-exchange-office-id",
    path: "exchange-office/:id()",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/exchange-office/[id].vue")
  },
  {
    name: "profile-admin-exchange-office-branches-id",
    path: "exchange-office/branches/:id()",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/exchange-office/branches/[id].vue")
  },
  {
    name: "profile-admin-exchange-office",
    path: "exchange-office",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/exchange-office/index.vue")
  },
  {
    name: "profile-admin",
    path: "",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/index.vue")
  },
  {
    name: "profile-admin-kyb",
    path: "kyb",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/kyb.vue")
  },
  {
    name: "profile-admin-orders-id",
    path: "orders/:id()",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/orders/[id].vue")
  },
  {
    name: "profile-admin-orders",
    path: "orders",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/orders/index.vue")
  },
  {
    name: "profile-admin-overlimit",
    path: "overlimit",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/overlimit.vue")
  },
  {
    name: "profile-admin-statistics",
    path: "statistics",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/statistics.vue")
  },
  {
    name: "profile-admin-transactions",
    path: "transactions",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/transactions.vue")
  },
  {
    name: "profile-admin-users-id",
    path: "users/:id()",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/users/[id].vue")
  },
  {
    name: "profile-admin-users",
    path: "users",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/users/index.vue")
  }
]
  },
  {
    name: "profile-orders-id",
    path: "orders/:id()",
    component: () => import("/vercel/path0/apps/bitbelicash/pages/profile/orders/[id].vue")
  },
  {
    name: "profile-overview",
    path: "overview",
    component: () => import("/vercel/path0/apps/bitbelicash/pages/profile/overview.vue")
  },
  {
    name: "profile-verify-result",
    path: "verify-result",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/verify-result.vue")
  },
  {
    name: "admin",
    path: "admin",
    meta: adminq5aC74u6KBMeta || {},
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin.vue"),
    children: [
  {
    name: "profile-admin-bank-accounts",
    path: "bank-accounts",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/bank-accounts.vue")
  },
  {
    name: "profile-admin-bank-id",
    path: "bank/:id()",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/bank/[id].vue")
  },
  {
    name: "profile-admin-bank",
    path: "bank",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/bank/index.vue")
  },
  {
    name: "profile-admin-charges",
    path: "charges",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/charges.vue")
  },
  {
    name: "profile-admin-exchange-office-id",
    path: "exchange-office/:id()",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/exchange-office/[id].vue")
  },
  {
    name: "profile-admin-exchange-office-branches-id",
    path: "exchange-office/branches/:id()",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/exchange-office/branches/[id].vue")
  },
  {
    name: "profile-admin-exchange-office",
    path: "exchange-office",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/exchange-office/index.vue")
  },
  {
    name: "profile-admin",
    path: "",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/index.vue")
  },
  {
    name: "profile-admin-kyb",
    path: "kyb",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/kyb.vue")
  },
  {
    name: "profile-admin-orders-id",
    path: "orders/:id()",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/orders/[id].vue")
  },
  {
    name: "profile-admin-orders",
    path: "orders",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/orders/index.vue")
  },
  {
    name: "profile-admin-overlimit",
    path: "overlimit",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/overlimit.vue")
  },
  {
    name: "profile-admin-statistics",
    path: "statistics",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/statistics.vue")
  },
  {
    name: "profile-admin-transactions",
    path: "transactions",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/transactions.vue")
  },
  {
    name: "profile-admin-users-id",
    path: "users/:id()",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/users/[id].vue")
  },
  {
    name: "profile-admin-users",
    path: "users",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/users/index.vue")
  }
]
  },
  {
    name: "inspector",
    path: "inspector",
    meta: inspectoriUqJe6hPJEMeta || {},
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/inspector.vue"),
    children: [
  {
    name: "profile-inspector-charges",
    path: "charges",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/inspector/charges.vue")
  },
  {
    name: "profile-inspector",
    path: "",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/inspector/index.vue")
  },
  {
    name: "profile-inspector-orders",
    path: "orders",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/inspector/orders.vue")
  },
  {
    name: "profile-inspector-transactions",
    path: "transactions",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/inspector/transactions.vue")
  },
  {
    name: "profile-inspector-users",
    path: "users",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/inspector/users.vue")
  }
]
  }
]
  },
  {
    name: "signup-success",
    path: "/signup-success",
    component: () => import("/vercel/path0/layers/modules/fubex-auth/pages/signup-success.vue")
  },
  {
    name: "update-password",
    path: "/update-password",
    component: () => import("/vercel/path0/layers/modules/fubex-auth/pages/update-password.vue")
  },
  {
    name: "verify-result",
    path: "/verify-result",
    component: () => import("/vercel/path0/apps/bitbelicash/pages/verify-result.vue")
  },
  {
    name: "localized-email-verified",
    path: "/:locale(en)/email-verified",
    component: () => import("/vercel/path0/layers/modules/fubex-auth/pages/email-verified.vue")
  },
  {
    name: "localized-index",
    path: "/:locale(en)",
    component: () => import("/vercel/path0/apps/bitbelicash/pages/index.vue")
  },
  {
    name: "localized-invite-userId",
    path: "/:locale(en)/invite/:userId()",
    component: () => import("/vercel/path0/layers/modules/fubex-auth/pages/invite/[userId].vue")
  },
  {
    name: "localized-login",
    path: "/:locale(en)/login",
    component: () => import("/vercel/path0/layers/modules/fubex-auth/pages/login.vue")
  },
  {
    name: "localized-logout",
    path: "/:locale(en)/logout",
    component: () => import("/vercel/path0/layers/modules/fubex-auth/pages/logout.vue")
  },
  {
    name: "localized-order-uuid",
    path: "/:locale(en)/order/:uuid()",
    meta: _91uuid_93zf836kyoD0Meta || {},
    component: () => import("/vercel/path0/apps/bitbelicash/pages/order/[uuid].vue")
  },
  {
    name: "localized-profile-parent",
    path: "/:locale(en)/profile",
    meta: profileVFLmNfYR3cMeta || {},
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile.vue"),
    children: [
  {
    name: "localized-account-en",
    path: "account",
    meta: accountbENOmKO8wKMeta || {},
    component: () => import("/vercel/path0/apps/bitbelicash/pages/profile/account.vue"),
    children: [
  {
    name: "localized-profile-account-bank-accounts-en",
    path: "bank-accounts",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/account/bank-accounts.vue")
  },
  {
    name: "localized-profile-account-change-password-en",
    path: "change-password",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/account/change-password.vue")
  },
  {
    name: "localized-profile-account-en",
    path: "",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/account/index.vue")
  },
  {
    name: "localized-profile-account-verify-en",
    path: "verify",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/account/verify.vue")
  }
]
  },
  {
    name: "localized-admin-en",
    path: "admin",
    meta: adminq5aC74u6KBMeta || {},
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin.vue"),
    children: [
  {
    name: "localized-profile-admin-bank-accounts-en",
    path: "bank-accounts",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/bank-accounts.vue")
  },
  {
    name: "localized-profile-admin-bank-id-en",
    path: "bank/:id()",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/bank/[id].vue")
  },
  {
    name: "localized-profile-admin-bank-en",
    path: "bank",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/bank/index.vue")
  },
  {
    name: "localized-profile-admin-charges-en",
    path: "charges",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/charges.vue")
  },
  {
    name: "localized-profile-admin-exchange-office-id-en",
    path: "exchange-office/:id()",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/exchange-office/[id].vue")
  },
  {
    name: "localized-profile-admin-exchange-office-branches-id-en",
    path: "exchange-office/branches/:id()",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/exchange-office/branches/[id].vue")
  },
  {
    name: "localized-profile-admin-exchange-office-en",
    path: "exchange-office",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/exchange-office/index.vue")
  },
  {
    name: "localized-profile-admin-en",
    path: "",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/index.vue")
  },
  {
    name: "localized-profile-admin-kyb-en",
    path: "kyb",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/kyb.vue")
  },
  {
    name: "localized-profile-admin-orders-id-en",
    path: "orders/:id()",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/orders/[id].vue")
  },
  {
    name: "localized-profile-admin-orders-en",
    path: "orders",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/orders/index.vue")
  },
  {
    name: "localized-profile-admin-overlimit-en",
    path: "overlimit",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/overlimit.vue")
  },
  {
    name: "localized-profile-admin-statistics-en",
    path: "statistics",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/statistics.vue")
  },
  {
    name: "localized-profile-admin-transactions-en",
    path: "transactions",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/transactions.vue")
  },
  {
    name: "localized-profile-admin-users-id-en",
    path: "users/:id()",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/users/[id].vue")
  },
  {
    name: "localized-profile-admin-users-en",
    path: "users",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/admin/users/index.vue")
  }
]
  },
  {
    name: "localized-bonus-en",
    path: "bonus",
    meta: bonusbrvmpruqgkMeta || {},
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/bonus.vue"),
    children: [
  {
    name: "localized-profile-bonus-en",
    path: "",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/bonus/index.vue")
  },
  {
    name: "localized-profile-bonus-rewards-en",
    path: "rewards",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/bonus/rewards.vue")
  },
  {
    name: "localized-profile-bonus-withdrawals-en",
    path: "withdrawals",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/bonus/withdrawals.vue")
  }
]
  },
  {
    name: "localized-profile-exchange-en",
    path: "exchange",
    component: () => import("/vercel/path0/apps/bitbelicash/pages/profile/exchange.vue")
  },
  {
    name: "localized-exchangeOffice-en",
    path: "exchangeOffice",
    meta: exchangeOfficeyQslI2ShU0Meta || {},
    component: () => import("/vercel/path0/apps/bitbelicash/pages/profile/exchangeOffice.vue"),
    children: [
  {
    name: "localized-profile-exchangeOffice-branches-id-en",
    path: "branches/:id()",
    component: () => import("/vercel/path0/apps/bitbelicash/pages/profile/exchangeOffice/branches/[id].vue")
  },
  {
    name: "localized-profile-exchangeOffice-branches-en",
    path: "branches",
    component: () => import("/vercel/path0/apps/bitbelicash/pages/profile/exchangeOffice/branches/index.vue")
  },
  {
    name: "localized-profile-exchangeOffice-clients-en",
    path: "clients",
    component: () => import("/vercel/path0/apps/bitbelicash/pages/profile/exchangeOffice/clients.vue")
  },
  {
    name: "localized-profile-exchangeOffice-employees-en",
    path: "employees",
    component: () => import("/vercel/path0/apps/bitbelicash/pages/profile/exchangeOffice/employees.vue")
  },
  {
    name: "localized-profile-exchangeOffice-en",
    path: "",
    component: () => import("/vercel/path0/apps/bitbelicash/pages/profile/exchangeOffice/index.vue")
  },
  {
    name: "localized-profile-exchangeOffice-settings-en",
    path: "settings",
    component: () => import("/vercel/path0/apps/bitbelicash/pages/profile/exchangeOffice/settings.vue")
  },
  {
    name: "localized-profile-exchangeOffice-transactions-en",
    path: "transactions",
    component: () => import("/vercel/path0/apps/bitbelicash/pages/profile/exchangeOffice/transactions.vue")
  }
]
  },
  {
    name: "localized-profile-history-en",
    path: "history",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/history.vue")
  },
  {
    name: "localized-profile-en",
    path: "",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/index.vue")
  },
  {
    name: "localized-inspector-en",
    path: "inspector",
    meta: inspectoriUqJe6hPJEMeta || {},
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/inspector.vue"),
    children: [
  {
    name: "localized-profile-inspector-charges-en",
    path: "charges",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/inspector/charges.vue")
  },
  {
    name: "localized-profile-inspector-en",
    path: "",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/inspector/index.vue")
  },
  {
    name: "localized-profile-inspector-orders-en",
    path: "orders",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/inspector/orders.vue")
  },
  {
    name: "localized-profile-inspector-transactions-en",
    path: "transactions",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/inspector/transactions.vue")
  },
  {
    name: "localized-profile-inspector-users-en",
    path: "users",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/inspector/users.vue")
  }
]
  },
  {
    name: "localized-profile-orders-id-en",
    path: "orders/:id()",
    component: () => import("/vercel/path0/apps/bitbelicash/pages/profile/orders/[id].vue")
  },
  {
    name: "localized-profile-overview-en",
    path: "overview",
    component: () => import("/vercel/path0/apps/bitbelicash/pages/profile/overview.vue")
  },
  {
    name: "localized-profile-verify-result-en",
    path: "verify-result",
    component: () => import("/vercel/path0/layers/modules/fubex-profile/pages/profile/verify-result.vue")
  }
]
  },
  {
    name: "localized-signup-success",
    path: "/:locale(en)/signup-success",
    component: () => import("/vercel/path0/layers/modules/fubex-auth/pages/signup-success.vue")
  },
  {
    name: "localized-update-password",
    path: "/:locale(en)/update-password",
    component: () => import("/vercel/path0/layers/modules/fubex-auth/pages/update-password.vue")
  },
  {
    name: "localized-verify-result",
    path: "/:locale(en)/verify-result",
    component: () => import("/vercel/path0/apps/bitbelicash/pages/verify-result.vue")
  }
]